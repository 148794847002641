import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Routes, Route } from "react-router-dom";
import Login from "./pages/loginPage/login";
import auth from "./services/authService";
import NewUserDashboard from "./pages/dashboardPage/dashboard";
import Dispute from "./pages/disputePage/Dispute";
import { useLocation } from 'react-router-dom';

function App() {
  const theme = useSelector((state) => state.theme.theme);
  // const [user, setUser] = useState(auth.getUser().UserLogin);
  // const [token, setToken] = useState('');
  const location = useLocation();


  function getQueryParameter(parameterName) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(parameterName);
}

  // useEffect(() => {
  //     const tokenValue = getQueryParameter('token');
  //     // setToken(tokenValue);
  //     auth.login(tokenValue);
  //     // window.location.reload();
  //     // console.log(tokenValue);
  // }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const shouldReload = queryParams.get('reload');
    const tokenValue = getQueryParameter('token');
      // setToken(tokenValue);
    auth.login(tokenValue);
    if (shouldReload === 'true') {
        // Remove the reload parameter from the URL
        queryParams.delete('reload');
        window.history.replaceState({}, document.title, location.pathname + '?' + queryParams.toString());

        // Reload the page only once
        window.location.reload();
    }
}, [location]);

//   useEffect(() => {
//     const tokenValue = getQueryParameter('token');
//     if (tokenValue) {
//         setToken(tokenValue);
//         auth.login(tokenValue);
//     }
// }, [])

  useEffect(() => {
    const importThemeStyle = async () => {
      const styleSheetId = "themeStyleSheet";
      const existingStyleSheet = document.getElementById(styleSheetId);
      if (existingStyleSheet) {
        existingStyleSheet.remove();
      }
      const link = document.createElement("link");
      link.id = styleSheetId;
      link.rel = "stylesheet";
      link.href = theme ? "./style.css" : "./style2.css";
      document.head.appendChild(link);
    };

    importThemeStyle();
  }, [theme]);

  return (
    <>
      <Routes>
        <Route path="/verify" element={<Login />} />
        {/* <Route path='/dashboard' element={<NewUserDashboard/>}/> */}
        {/* <Route path='/dispute' element={<Dispute/>}/> */}
        <Route path='/' element={<Login/>}/>
      </Routes>
    </>
  );
}

export default App;
