import React,{useRef, useState, useEffect} from 'react';
import VerifyButton from './verifyButton';
import { useDispatch } from 'react-redux';
import { changeStage } from "../../../features/verificationStage/verificationSlice";
import useLanguage from "../../../local/saveLanguage";
import http from "../../../services/httpService";
import auth from "../../../services/authService";
import DataCompilation from './dataCompilation';

const MidCredit = () => {
    const translate = useLanguage.getLang();
    const [mobileEr, setMobileEr] = useState(false);
    const [mobileno, setMobileno]=useState("");
    const [otp, setOtp]=useState("");
    const [dataCompilation, setDataCompilation]=useState(false);
    const [otpErr, setOtpEr] = useState(false);
    const [aEErr, setAEErr] = useState(false);
    const [nameErr, setNameErr]=useState(false);
    const user=auth.getUser().UserLogin;
    const dispatch = useDispatch();
    const [otpSend, setOtpSend]=useState(false);
    const [disabled, setDisabled] = useState(true);
    const [withOutOTP, setWithOutOTP]=useState(false);
    
    const inputRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
      ];
      const inputRefs2 = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
      ];
      console.log(inputRefs);
      const handleAlreadyExists=async(aadhaar1)=>{
        try {
          const response = await http.post2("/alreadyExists", {mobileno:aadhaar1});
          if(response.status===200){
             if(response.data==="Already Exists"){
              // inputRefs.map((ref) => ref.current.value="");
              setAEErr(true);
              setDisabled(true);
            }
          }
        } catch (error) {
          dispatch(changeStage('overload'));
          setDisabled(false);
          console.error("Error:", error);
        }
      }

      const handleKeyDown = (index, e) => {
        
        setOtp(false);
        setAEErr(false);
        setNameErr(false);
        setMobileEr(false);
        if (e.key === "Backspace" && index > 0 && e.target.value ==="") {
          const prevIndex = index-1;
          inputRefs[prevIndex].current.focus();
        }
        else if(/^[A-Z]$/i.test(e.key)){
          inputRefs[index].current.value = "";
        }
        else if (/^[0-9]$/i.test(e.key)) {
          const nextIndex = index + 1;
          if (nextIndex < inputRefs.length) {
            inputRefs[index].current.value = e.key;
            inputRefs[nextIndex].current.focus();
          }
        }
        else if (
          index === 0 &&
          e.key !== "Backspace" &&
          (/^[0-9]$/i.test(e.key))
        ) {
          // Prevent typing in the first input field if it's not a number or Backspace
          inputRefs[0] = e.key;
          e.preventDefault();
        }else{
          inputRefs[index].current.value=''
        }
        
        let mobileno1=inputRefs.map((ref) => ref.current?.value).join("");
        if(mobileno1.length===10){
          setMobileno(mobileno1);
          setDisabled(false);
        }
        else setDisabled(true);
      };

      const handleKeyDownOtp = (index, e) => {
        // console.log(inputRefs[0].current.value);
        setOtp(false);
        setAEErr(false);
        setNameErr(false);
        setMobileEr(false);
        if (e.key === "Backspace" && index > 0 && e.target.value === "") {
          const prevIndex = index-1;
          inputRefs2[prevIndex].current.focus();
        }
        else if(/^[A-Z]$/i.test(e.key)){
          inputRefs[index].current.value = "";
        }
         else if (e.key >= "0" && e.key <= "9") {
          const nextIndex = index + 1;
          if (nextIndex < inputRefs2.length) {
            inputRefs2[index].current.value = e.key;
            inputRefs2[nextIndex].current.focus();
          }
        }
        else if (
          index === 0 &&
          e.key !== "Backspace" &&
          (e.key < "0" || e.key > "9")
        ) {
          inputRefs2[0] = e.key;
          e.preventDefault();
        }else{
          inputRefs[index].current.value=''
        }
        let otp1=inputRefs2.map((ref) => ref.current?.value).join("");
        if(otp1.length===6){
          setOtp(otp1);
          setDisabled(false);
        }
        else setDisabled(true);
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
        setDisabled(true);
        setDataCompilation(true);
        
        let mobileno = inputRefs.map((ref) => ref.current.value).join("");
        console.log(mobileno);
        try {
          const response = await http.post2("/nbfcCreditOtpget", {mobileno, mid: user._id});
          if(response.status===200){
            // setDataCompilation(false);
            if(response.data==="Otp Send"){
              console.log(response.data);
              setOtpSend(true);
            }
            else if(response.data==="Wrong Input"){
              console.log(response.data);
              // inputRefs.map((ref) => ref.current.value="");
              setMobileEr(true);
            }
          }
          else if(response.status===400){
            console.log(response);
            dispatch(changeStage("overload"));
          }
        } catch (error) {
          dispatch(changeStage("overload"));
          console.error("Error:", error);
        }
        setDisabled(false);
      };

      const handleSubmit2=async(e)=>{
        e.preventDefault();
        setDisabled(true);
        setDataCompilation(true);
        let mobileno = inputRefs.map((ref) => ref.current.value).join("");
        try {
          const response = await http.post2("/nbfcCreditOtpverify", {mobileno, otp:otp, mid: user._id, });
          console.log(response.data, "response.data")
          if(response.status===200){
            // setDataCompilation(false);
            if(response.data==="Already Exists"){
              // inputRefs.map((ref) => ref.current.value="");
              // inputRefs2.map((ref)=>ref.current.value="");
              setAEErr(true);
              setOtpSend(false);
            }
            else if(response.data==="Name not matched"){
              // inputRefs.map((ref) => ref.current.value="");
              // inputRefs2.map((ref)=> ref.current.value="");
              setNameErr(true);
              setOtpSend(false);
            }
            else if(response.data==="Wrong Otp"){
              // inputRefs2.map(ref=>ref.current.value="");
              setOtpEr(true);
            }
            else {
              auth.login(response.data.token);
              dispatch(changeStage(response.data.verificationStage));
            }
          }
          else if(response.status===400){
            console.log(response);
            dispatch(changeStage("overload"));
            // setMobileEr(true);
          }
        } catch (error) {
          // setMobileEr(true);
          dispatch(changeStage("overload"));
          console.error("Error:", error);
        }
        setDisabled(false);
      }

      const handleSubmit3=async(e)=>{
        console.log(withOutOTP, 'withoutotp');
        e.preventDefault();
        setDisabled(true);
        setDataCompilation(true);
        let mobileno = inputRefs.map((ref) => ref.current?.value).join("");
        try {
          const response = await http.post2("/nbfcCreditWithOutOTPVerify", {mobileno,  mid: user._id, });
          console.log(response.data, "response.data");
          if(response.status===200){
            if(response.data==="Wrong Input"){
              setMobileEr(true);
            }
            else {
              auth.login(response.data.token);
              dispatch(changeStage(response.data.verificationStage));
            }
          }
          else if(response.status===400){
            console.log(response);
            dispatch(changeStage("overload"));
          }
        } catch (error) {
          dispatch(changeStage("overload"));
          console.error("Error:", error);
        }
        setDisabled(false);
      }

      const handleSkip = async (e) => {
        e.preventDefault();
        console.log("skip clicked");
        try {
          let response = await http.post2(`/skipNbfcCredit`, { mid: user._id }); // Corrected URL format
          if (response.status === 200) {
            auth.login(response.data.token);
            dispatch(changeStage(response.data.verificationStage));
            const url = `/verify?token=${response.data.token}`;
            window.location.href = url;
          }
        } catch (err) {
          console.log(err);
        }
      };

      useEffect(() => {
        if (mobileno) {
          const digits = mobileno.toString().split('').map(Number);
          digits.forEach((digit, index) => {
            if (inputRefs[index] && inputRefs[index].current) {
              inputRefs[index].current.value = digit;
            }
          });
        }
      },[mobileno, dataCompilation]);
      

      useEffect(() => {
        const timer = setTimeout(() => {
          setDataCompilation(false);
        }, 10000);
        return () => clearTimeout(timer);
      }, [dataCompilation]);

    return (
      <>{
        dataCompilation ?<>
          <DataCompilation/>
        </>:
        <div className="login-mid">
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-lg-6 login-mid-left">
                        <h2 data-translate="tran-1">{translate?.["Securely Verify Your Credit Score"] ?? "Securely Verify Your Credit Score"}</h2>
                    </div>
                    <div className="col-xl-6 col-lg-6 offset-xl-1">
                        <div className="card pb-normal ad-box">
                            <div className="vf-content">
                                <h1 data-translate="tran-2">{translate?.["Enter Your Mobile Number"] ?? "Enter Your Mobile Number"}</h1>
                                <p data-translate="tran-3">{translate?.["To access your credit score securely, please enter your mobile number below. We'll send you a one-time password (OTP) to verify your identity and ensure the confidentiality of your information."] ?? "To access your credit score securely, please enter your mobile number below. We'll send you a one-time password (OTP) to verify your identity and ensure the confidentiality of your information."}</p>
                                <p data-translate="tran-4">{translate?.["For any queries or assistance, feel free to call us at +91-9699900111."] ?? "For any queries or assistance, feel free to call us at +91-9699900111."}</p>
                                <p><strong data-translate="tran-5">{translate?.["Thank you for entrusting PrivateCourt. Your security is our priority."] ?? "Thank you for entrusting PrivateCourt. Your security is our priority."}</strong></p>
                            </div>
                            <div className="otp-boxs otp-pan d-flex flex-wrap justify-content-center udyam-number-box aadhar-number-box">
                                {[...Array(10)].map((_, index) => (
                                    <React.Fragment key={index}>
                                        <input
                                            className="text-center form-control rounded"
                                            type="text"
                                            onKeyUp={(e)=>handleKeyDown(index, e)}
                                            maxLength="1"
                                            ref={inputRefs[index]}
                                        />
                                        
                                    </React.Fragment>
                                ))}
                                {/* <h6 className="field-mid-text">1234</h6> */}
                            </div>
                            {/* <h5 className="mb-1 mt-3" data-translate="tran-6">Enter Your Aadhaar OTP Here</h5> */}
                            {/* checkbox part */}
                            {
                              !otpSend && <div className="d-flex flex-row justify-content-center">                                
                                <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={withOutOTP}
                                      onChange={()=>setWithOutOTP(prev=>!prev)}
                                      id="withOutOTP"
                                      // value={withOutOTP}
                                      
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="withOutOTP"
                                    >
                                      Verify without OTP
                                    </label>
                                  </div>
                                </div>}
                            {
                              otpSend && <>
                                <h5 className="mb-1 mt-3" data-translate="tran-6">{translate?.["Upon submission, check your messages promptly for the OTP. This step ensures your financial information remains protected while you gain valuable insights into your credit profile."]??"Upon submission, check your messages promptly for the OTP. This step ensures your financial information remains protected while you gain valuable insights into your credit profile."}</h5>
                                <h5 className="mb-1 mt-3" data-translate="tran-6">{translate?.["Enter Your OTP Here"]??"Enter Your OTP Here"}</h5>
                               <div className="otp-boxs d-flex flex-row justify-content-center aadhar-number-box">
                                {[...Array(6)].map((_, index) => (
                                    <input
                                        key={index}
                                        className="text-center form-control rounded"
                                        type="text"
                                        maxLength="1"
                                        onKeyUp={(e)=>handleKeyDownOtp(index, e)}
                                        ref={inputRefs2[index]}
                                    />
                                ))}
                              </div>
                              </>
                            }

                            {mobileEr && <>
                              <div className="alert alert-danger text-danger vfe-content" role="alert">
                                <div className="text-center">
                                <i className="bi bi-exclamation-triangle-fill text-danger"></i>
                                </div>
                                <h4 className="alert-heading" data-translate="aaav-invalid-heading">{translate?.["Error: Invalid Mobile Number"]??"Error: Invalid Mobile Number"}</h4>
                                <p data-translate="aaav-invalid-title-1">{translate?.["We apologize for the inconvenience. The Mobile number entered is invalid. Please double-check and ensure that you have entered the"]??"We apologize for the inconvenience. The Mobile number entered is invalid. Please double-check and ensure that you have entered the"} <strong>{translate?.["Applicant’s correct 10-digit Mobile number."]??"Applicant’s correct 10-digit Mobile number."}</strong></p>
                                <p data-translate="aaav-invalid-title-2">{translate?.["If you continue to face issues, verify the"]??"If you continue to face issues, verify the"} <strong>{translate?.["Applicant’s"]??"Applicant’s"}</strong> {translate?.["Mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation."]??"Mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation."}</p>
                                <p><strong data-translate="aaav-invalid-title-3">{translate?.["Thank you for entrusting PrivateCourt. Your security is our priority."]??"Thank you for entrusting PrivateCourt. Your security is our priority."}</strong></p>
                              </div></>}

                              {otpErr && <>
                              <div className="alert alert-danger text-danger vfe-content" role="alert">
                                <div className="text-center">
                                <i className="bi bi-exclamation-triangle-fill text-danger"></i>
                                </div>
                                <h4 className="alert-heading" data-translate="aaav-invalid-heading">{translate?.["Error: Invalid OTP"]??"Error: Invalid OTP"}</h4>
                                <p data-translate="aaav-invalid-title-1">{translate?.["We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again."]??"We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again."}</p>
                                <p data-translate="aaav-invalid-title-2">{translate?.["If you continue to face issues, verify the"]??"If you continue to face issues, verify the"} <strong>{translate?.["Applicant’s"]??"Applicant’s"}</strong> {translate?.["mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation."]??"mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation."}</p>
                                <p><strong data-translate="aaav-invalid-title-3">{translate?.["Thank you for entrusting PrivateCourt. Your security is our priority."]??"Thank you for entrusting PrivateCourt. Your security is our priority."}</strong></p>
                              </div></>}

                              {/* {aEErr && <>
                              <div className="alert alert-danger text-danger vfe-content" role="alert">
                                <div className="text-center">
                                <i className="bi bi-exclamation-triangle-fill text-danger"></i>
                                </div>
                                <h4 className="alert-heading" data-translate="aaav-invalid-heading">{translate?.["Error: User already exists"]??"Error: User already exists"}</h4>
                                <p data-translate="aaav-invalid-title-1">{translate?.["We apologize for the inconvenience.  The Aadhaar number provided already exists in our records. Please provide a different Aadhaar number for verification."]??"We apologize for the inconvenience.  The Aadhaar number provided already exists in our records. Please provide a different Aadhaar number for verification."}</p>
                                <p data-translate="aaav-invalid-title-2">{translate?.["If you continue to face issues, verify the"]??"If you continue to face issues, verify the"} <strong>{translate?.["Applicant’s"]??"Applicant’s"}</strong> {translate?.["mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation."]??"mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation."}</p>
                                <p><strong data-translate="aaav-invalid-title-3">{translate?.["Thank you for entrusting PrivateCourt. Your security is our priority."]??"Thank you for entrusting PrivateCourt. Your security is our priority."}</strong></p>
                              </div></>} */}

                              {/* {nameErr && <>
                              <div className="alert alert-danger text-danger vfe-content" role="alert">
                                <div className="text-center">
                                <i className="bi bi-exclamation-triangle-fill text-danger"></i>
                                </div>
                                <h4 className="alert-heading" data-translate="aaav-invalid-heading">{translate?.["Error: Name Not Matched"]??"Error: Name Not Matched"}</h4>
                                <p data-translate="aaav-invalid-title-1">{translate?.["We apologize for the inconvenience. The name provided does not match the PAN records. Please ensure that the name entered matches the information on your PAN card and try again."]??"We apologize for the inconvenience. The name provided does not match the PAN records. Please ensure that the name entered matches the information on your PAN card and try again."}</p>
                                <p data-translate="aaav-invalid-title-2">{translate?.["If you continue to face issues, verify the"]??"If you continue to face issues, verify the"} <strong>{translate?.["Applicant’s"]??"Applicant’s"}</strong> {translate?.["mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation."]??"mobile Number or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation."}</p>
                                <p><strong data-translate="aaav-invalid-title-3">{translate?.["Thank you for entrusting PrivateCourt. Your security is our priority."]??"Thank you for entrusting PrivateCourt. Your security is our priority."}</strong></p>
                              </div></>} */}

                            <div className="d-flex justify-content-end align-items-center skip-sec">
                                {/* <h4
                                  id="skipButton"
                                  className="align-self-end me-2"
                                  data-translate="skip-btn" 
                                  onClick={handleSkip}
                                  style={{ cursor: "pointer" }}
                                >
                                  {translate?.["Skip"]??"Skip"}
                                </h4> */}
                                <VerifyButton btnName={otpSend?"Verify OTP":"Verify"} onSubmit={withOutOTP?handleSubmit3:otpSend?handleSubmit2: handleSubmit} disabledBtn={(mobileno.length!==10 || (otpSend && disabled)) || disabled}/>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
          }
          </>
    );
};

export default MidCredit;
