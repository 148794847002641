import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeTheme } from "../../../features/theme/themeSlice";
import { fontChange } from "../../../features/font/fontSlice";
import bgLogo from "./../../../images/logo-bg.png";
import bgLogoWhite from "./../../../images/logo-white-bg.png";


const LoginHeader = ({ children }) => {
  const theme = useSelector((state) => state.theme.theme);
  const fontSize =useSelector((state)=>state.font.fontSize);
  console.log(fontSize,"font");
  
  const dispatch = useDispatch();
  const handleThemeChange = () => {
    dispatch(changeTheme());
  };

  const handleDecrease=()=>{
    dispatch(fontChange(fontSize.curr-2));
  }
  const handleIncrease=()=>{
    dispatch(fontChange(fontSize.curr+2));
  }
  const handleReset=()=>{
    dispatch(fontChange(16));
  }

  const changeFontSizeAndLineHeight = (changeAmount) => {
    const elementsToChange = document.querySelectorAll('.login-mid .container *');
    elementsToChange.forEach((element) => {
      const currentSize = window.getComputedStyle(element).getPropertyValue('font-size');
      let changeAmount=0
      if(fontSize.curr>fontSize.prev){
        changeAmount=2;
      }
      else if(fontSize.curr<fontSize.prev){
        changeAmount=-2;
      }
      else changeAmount=0;
      // console.log(currentSize,'currSize')
      const currentLineHeight = window.getComputedStyle(element).getPropertyValue('line-height');
      const newSize = parseFloat(currentSize) + changeAmount;
      const newLineHeight = parseFloat(currentLineHeight) + changeAmount;
      if(changeAmount!=0){
        element.style.fontSize = `${newSize}px`;
        element.style.lineHeight = `${newLineHeight}px`;
      }
      else{
        var elementsToReset = document.querySelectorAll('.login-mid .container *');
        elementsToReset.forEach(function(element) {
            element.style.fontSize = ''; // Reset font size to default
            element.style.lineHeight = ''; // Reset line height to default
        });
      }

    });
  };

  useEffect(()=>{
    changeFontSizeAndLineHeight(fontSize);
  },[fontSize])

  return (
    <div className="login-header">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="main-text-logo">
              <h1>
                PRIVATE<span>COURT</span>
              </h1>
              <p>
                The International Court <span>of</span>{" "}
                <strong>arbitration</strong>
              </p>
            </div>
          </div>
          {/* <!-- /.col -->  */}
          <div className="col">
          {/* <link id="stylesheet" rel="stylesheet" href={!theme ? "../../../style2.css": "../../../style.css"} /> */}
          {/* {theme && <link id="stylesheet" rel="stylesheet" href="../../../style2.css" />} */}
            <ul className="list-inline fs-ld text-end mb-0">
              <li className="list-inline-item font-size-fun" id="increase" onClick={handleIncrease}>
                <span>A+</span>
              </li>
              <li className="list-inline-item font-size-fun" id="decrease" onClick={handleDecrease}>
                <span>A-</span>
              </li>
              <li className="list-inline-item font-size-fun" id="reset" onClick={handleReset}>
                <span>A</span>
              </li>
              <li
                className={`list-inline-item ${theme ? "d-none-dark" : "me-1 d-none-light"}`}
              >
                <span id={theme ? "style2Btn" : "style1Btn"} onClick={handleThemeChange} className={`color-switcher ${ theme ? "dark-style" : "light-style"}`}
                ></span>
              </li>
              
            </ul>
          </div>
        </div>
        {/* <!-- /.row --> */}
      </div>
      {/* <!-- /.container --> */}
    </div>
    //   <!-- /.login-header -->
  );
};

export default LoginHeader;
