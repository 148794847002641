import Cookies from 'js-cookie';
const keyName1 = "user1";
const keyName2 = 'attempts';


// let user1={UserLogin:{
//   "_id": {
//     "$oid": "663c7908e828722b7b7f2141"
//   },
//   "email": "balram@privatecourt.in",
//   "cinArray": [],
//   "otp": 255735,
//   "nickName": "",
//   "companyName": "",
//   "personalName": "",
//   "pan_number": "",
//   "panCategory": "",
//   "gstinArray": [],
//   "gstPanArray": [],
//   "gstCin": [],
//   "accountStatus": "verified",
//   "wrongAttempts": 0,
//   "panPersonFullName": [],
//   "verificationStage": "pan",
//   "gstPan": [],
//   "midArray": [],
//   "__v": 0,
//   "full_name": "BALRAM"
// }}


function login(obj) {
  let expTime=60*60*1000;
  const expirationDate = new Date(Date.now() + expTime);
  Cookies.set(keyName1, obj, { expires: expirationDate, path: '/' });
}

function logout() {
  Cookies.remove(keyName1);
}

function getUser() {
  let token = Cookies.get(keyName1);
    try{
        const [header, payload, signature] = token.split(".");
        const decodedPayload = JSON.parse(atob(payload));
        return decodedPayload
        
    }
    catch(err){
        console.log(err);
    }
    // return user1;
  return false;
}

export default {
  login,
  logout,
  getUser,
};