import {createSlice} from "@reduxjs/toolkit";
import auth from "../../services/authService";



const initialState={
    verificationStage: auth?.getUser()?.UserLogin?.verificationStage || "Thank"
    // verificationSlice:"credit"
}

// console.log(auth.getUser().UserLogin);
//  auth?.getUser()?.UserLogin?.verificationStage || "email"
 
export const verificationSlice=createSlice({
    name:"verificationStage",
    initialState,
    reducers:{
        changeStage:(state, action)=>{
            state.verificationStage=action.payload;
        }
    }
});

export const {changeStage}=verificationSlice.actions;
export default verificationSlice.reducer;